import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SolutionArticle from "../components/solutionArticle"
import RouteListing from "../components/routeListing"

const IndexPage = () => {

    const product = require('../../product.json');

    const _routes = product?.openapi?.paths || {};
    const routes = [];

    const definitions = product?.openapi?.components?.schemas || [];

    Object.keys(_routes).forEach(key => {

        const route = _routes[key];

        Object.keys(route).forEach(method => {

            routes.push({
                ...route.get,
                method,
                path: key
            });

        })

    })

    return <Layout>
        <section class="mt-16" id="features">
            <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                <div class="mx-auto h-full py-16 max-w-lg text-center lg:mx-0 lg:text-left flex align-top flex-col">
                    <h1 class="text-3xl font-bold sm:text-4xl">Routes</h1>
                    <p class="mt-4 text-gray-600">
                    {product.name} contains {routes.length} unique endpoints, or 'routes' as we like to call them. These routes manage all of the logic behind {product.name}- making it accessible to developers.
                    </p>
                </div>
            </div>
        </section>
        <section>
            <div class="mx-auto max-w-screen-xl px-4 pb-16 sm:px-6 lg:px-8">
                <div class="grid grid-cols-2 gap-4">
                    {
                        routes.map((item) => RouteListing({ ...item, href: `/products/${item.slug}` }))
                    }
                </div>
            </div>
        </section>
    </Layout>
}

export const Head = () => <Seo title="Solutions" />

export default IndexPage
