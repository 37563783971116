import * as React from "react"
import RouteStyle from "./routeStyle";

export default function RouteListing({ method, path, summary, onClick }) {

    return (<a
        class="relative block rounded-xl border border-gray-100 px-4 py-2 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
        onClick={() => { if (onClick != null) onClick(); }}
        href={`/routes/${method}${path}`}
    >
        <h2 class="font-bold"><RouteStyle path={path} /></h2>
        <p class="block text-sm text-gray-600">
            {summary}
        </p>
    </a>);

    

}